<template>
  <div class="Page" id="top">
    <div class="main-slider fullwidth-bg d-none d-lg-block">
      <b-container>
        <b-row>
          <b-col cols="7" class="pt-5 bring-to-front">
            <span class="slogon-text">TARIFICATION</span>
            <h1 class="main-headline mt-4 big-title">
              <strong> Parlons de chiffres </strong>
            </h1>
            <b-row class="mt-4">
              <b-col>
                <p class="text-dark">
                  Le coût de livraison sur ALTHIQA dépend de votre plan de
                  livraison, de la catégorie de votre produit, de votre
                  stratégie de stockage et d'autres variables. Les options sont
                  flexibles, de sorte que vous pouvez trouver la combinaison qui
                  fonctionne le mieux pour vous et vos objectifs.
                </p>
                <p class="small-text text-dark">
                  Touts les prix indiqués dans althiqa.ma sont
                  <strong>hors TVA</strong>
                </p>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="5 side-picture">
            <img src="~@/assets/imgs/althiqa-box.png" alt="Althiqa BOX" />
          </b-col>
          <b-col> </b-col>
        </b-row>
      </b-container>
    </div>
    <div
      class="
        main-slider
        fullwidth-bg-mobile
        d-xs-block d-sm-block d-md-block d-lg-none
      "
    >
      <b-container>
        <b-row>
          <b-col lg="12" class="pt-5 bring-to-front">
            <span class="slogon-text">TARIFICATION</span>
            <h1 class="main-headline mt-4 big-title">
              <strong> Parlons de chiffres </strong>
            </h1>
            <b-row class="mt-4">
              <b-col>
                <p class="text-dark">
                  Le coût de livraison sur ALTHIQA dépend de votre plan de
                  livraison, de la catégorie de votre produit, de votre
                  stratégie de stockage et d'autres variables. Les options sont
                  flexibles, de sorte que vous pouvez trouver la combinaison qui
                  fonctionne le mieux pour vous et vos objectifs.
                </p>
                <p class="small-text text-dark">
                  Touts les prix indiqués dans althiqa.ma sont
                  <strong>hors TVA</strong>
                </p>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12 mt-5 mb-5">
            <img
              src="~@/assets/imgs/althiqa-box.png"
              alt="Althiqa BOX"
              width="100%"
            />
          </b-col>
          <b-col> </b-col>
        </b-row>
      </b-container>
    </div>
    <b-container>
      <b-row>
        <b-col sm="12" lg="3" class="mb-4">
          <div class="block-shadow-no-border">
            <strong> Frais de livraison </strong>
            <p class="small-text mb-3">
              Les frais de livraison de vos commandes varient selon le volume,
              le poids, la distance et la catégorie.
            </p>
            <router-link to="#frais-de-livraison" class="scroll-link">
              Voir les frais de<br />livraison <b-icon-arrow-down-short />
            </router-link>
          </div>
        </b-col>
        <b-col sm="12" lg="3" class="mb-4">
          <div class="block-shadow-no-border">
            <strong> Comparatif de plans de livraison </strong>
            <p class="small-text mt-3">
              Le plan Standard coûte 13 DH par livraison, et le plan Fulfillment
              coûte 13 DH par livraison, indépendamment du nombre de livraisons.
            </p>

            <router-link to="#frais-de-livraison" class="scroll-link">
              Voir le comparatif <br />de plans <b-icon-arrow-down-short />
            </router-link>
          </div>
        </b-col>
        <b-col sm="12" lg="3" class="mb-4">
          <div class="block-shadow-no-border">
            <strong> Simulateur de frais </strong>
            <p class="small-text mt-3">
              Le simulateur Althiqa calcule le prix de livraison à travers trois
              paramètres (volume, poids et distance du trajet), Ce programme de
              tarification est connu sous le nom de DIM (le poids volumétrique)
            </p>

            <router-link to="#frais-de-livraison" class="scroll-link">
              Consulter le simulateur <br />de frais <b-icon-arrow-down-short />
            </router-link>
          </div>
        </b-col>
        <b-col sm="12" lg="3" class="mb-4">
          <div class="block-shadow-no-border">
            <strong> Autres coûts </strong>
            <p class="small-text mt-3">
              Frais de retours, échanges, stockage et des services non-planifiés...
            </p>

            <router-link to="#frais-de-livraison" class="scroll-link">
              Voir les autres coûts <b-icon-arrow-down-short />
            </router-link>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <b-container class="mt-5">
      <b-row>
        <b-col cols="1"> </b-col>
        <b-col cols="10" class="block-black mb-5">
          <p class="title">
            <b-icon-question-circle-fill class="mr-2" />
            <strong> Le saviez-vous </strong>
          </p>
          <h3>
            <strong class="white-text"> Être payé et payer des frais </strong>
          </h3>
          <p class="m-0">
            Lorsque vous configurez un compte vendeur, vous devez indiquer un ou
            plusieurs comptes bancaires. ALTHIQA prélèvera de la carte de crédit
            pour couvrir vos frais et les bénéfices de chaque vente seront
            versés sur votre compte bancaire.
          </p>
        </b-col>
      </b-row>
      <b-row class="mt-5">
        <b-col id="frais-de-livraison">
          <h2>
            <strong> Frais de livraison </strong>
          </h2>
          <p class="text-dark">
            Une livraison est la composition d'un ou plusieurs colis, goupe ou
            separe expedie a une adresse.<br />
            Les vendeurs paient les frais sur chaque livraison effectuer.<br />
            Althiqa déduit le cout de livraison a partir du poids ou du volume
            totale des colis prenenant en consideration la categorie des
            produits et l'adresse d'expedition.
          </p>
        </b-col>
      </b-row>
      <div class="block-shadow mb-3">
        <b-row>
          <b-col>
            <h4>
              <strong>
                Barème des frais de livraison (à compter du Février 2024)
              </strong>
            </h4>
          </b-col>
        </b-row>
        <b-row class="table-head">
          <b-col cols="4">
            <strong> Catégorie </strong>
          </b-col>
          <b-col cols="5">
            <strong> Calcule des frais de livraison </strong>
          </b-col>
          <b-col cols="3">
            <strong> Frais de livraison minimum </strong>
          </b-col>
        </b-row>
        <b-row class="table-item">
          <b-col cols="4">
            <p class="small-text">Livraison avec ramassage</p>
          </b-col>
          <b-col cols="5">
            <p class="small-text">
              25.00 DH + frais sur poids ou volume additionnel + frais
              d'emballage additionnel + frais sur distance additionnel
            </p>
          </b-col>
          <b-col cols="3">
            <p class="small-text">25.00 DH</p>
          </b-col>
        </b-row>
        <b-row class="table-item">
          <b-col cols="4">
            <p class="small-text">Fulfilment</p>
          </b-col>
          <b-col cols="5">
            <p class="small-text">
              30.00 DH + frais sur poids ou volume additionnel + frais
              d'emballage additionnel + frais sur distance additionnel
            </p>
          </b-col>
          <b-col cols="3">
            <p class="small-text">30.00 DH</p>
          </b-col>
        </b-row>
        <b-row class="table-item">
          <b-col cols="4">
            <p class="small-text">Messagerie</p>
          </b-col>
          <b-col cols="5">
            <p class="small-text">
              25.00 DH + frais sur poids ou volume additionnel
            </p>
          </b-col>
          <b-col cols="3">
            <p class="small-text">25.00 DH</p>
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col>
          <p class="text-dark">
            Vous avez opte pour livrer vos produits de valeur avec ALTHIQA ?<br />
            Le choix de la declaration sur valeur de vos produits vous protege
            en cas de perte, vole ou incendie.
          </p>
          <router-link to="/" class="forword-link">
            <strong>
              En savoir plus sur la declaration sur valeur
              <b-icon-arrow-right-short class="link-icon" />
            </strong>
          </router-link>
        </b-col>
      </b-row>
<!--      //**************************************************************************************-->
      <b-row class="top-space  d-none d-lg-block d-xl-block">
        <b-col>
          <h2>
            <strong> L'emballage et le groupage </strong>
          </h2>
          <p class="text-dark"></p>
        </b-col>
      </b-row>
      <div class="block-shadow mb-3  d-none d-lg-block d-xl-block ">
        <b-row>
          <b-col>
            <h4>
              <strong> Couts d'emballage (non vestimentaire) </strong>
            </h4>
          </b-col>
        </b-row>
        <b-row class="table-head">
          <b-col cols="2">
            <strong> Code </strong>
          </b-col>
          <b-col cols="3">
            <strong> Type </strong>
          </b-col>
          <b-col cols="3">
            <strong> Dimantions </strong>
          </b-col>
          <b-col cols="2">
            <strong> Volume </strong>
          </b-col>
          <b-col cols="2">
            <strong> Couts </strong>
          </b-col>
        </b-row>
        <b-row class="table-item">
          <b-col cols="2">
            <p class="small-text">A1</p>
          </b-col>
          <b-col cols="3">
            <p class="small-text">Carton - Carry-on</p>
          </b-col>
          <b-col cols="3">
            <p class="small-text">30 x 20 x 10 cm</p>
          </b-col>
          <b-col cols="2">
            <p class="small-text">6000 cm<sup>3</sup></p>
          </b-col>
          <b-col cols="2">
            <p class="small-text">1.8 DH</p>
          </b-col>
        </b-row>
        <b-row class="table-item">
          <b-col cols="2">
            <p class="small-text">0B0</p>
          </b-col>
          <b-col cols="3">
            <p class="small-text">Bag - Carry-on</p>
          </b-col>
          <b-col cols="3">
            <p class="small-text">30 x 20 cm</p>
          </b-col>
          <b-col cols="2">
            <p class="small-text">--</p>
          </b-col>
          <b-col cols="2">
            <p class="small-text">--</p>
          </b-col>
        </b-row>
        <b-row class="table-item">
          <b-col cols="2">
            <p class="small-text">1C0</p>
          </b-col>
          <b-col cols="3">
            <p class="small-text">Enveloppe plus - Nano</p>
          </b-col>
          <b-col cols="3">
            <p class="small-text">5 x 5 cm</p>
          </b-col>
          <b-col cols="2">
            <p class="small-text">--</p>
          </b-col>
          <b-col cols="2">
            <p class="small-text">1.10 DH</p>
          </b-col>
        </b-row>
        <b-row class="table-item">
          <b-col cols="2">
            <p class="small-text">W0</p>
          </b-col>
          <b-col cols="3">
            <p class="small-text">Wrap - Oversize</p>
          </b-col>
          <b-col cols="3">
            <p class="small-text">-</p>
          </b-col>
          <b-col cols="2">
            <p class="small-text">--</p>
          </b-col>
          <b-col cols="2">
            <p class="small-text">3.00 DH</p>
          </b-col>
        </b-row>
      </div>
      <!-- ***************************************************************************************************************************-->
      <!-- ********************************************* mobile  version of packing table ************************************************-->
      <b-row class="top-space d-xl-none d-lg-none">
        <b-col >
          <h2>
            <strong> L'emballage et le groupage </strong>
          </h2>
          <p class="text-dark"></p>
        </b-col>
      </b-row>
      <div class="block-shadow mb-3  d-xl-none d-lg-none">
        <b-row>
          <b-col cols="8">
            <h4>
              <strong> Couts d'emballage (non vestimentaire) </strong>
            </h4>
          </b-col>
          <!--    dropdown       -->
          <b-col cols="3"   >
            <b-dropdown id="dropdown-header" v-bind:text="dropdownPackageLabel" class="package-dropdown-btn" variant="warrning"  >
              <b-dropdown-item-button aria-describedby="dropdown-header-label" @click="coutsTableShow">
                Couts
              </b-dropdown-item-button>
              <b-dropdown-item-button aria-describedby="dropdown-header-label" @click="dimantionTableShow" >
                Dimantions
              </b-dropdown-item-button>
              <b-dropdown-item-button aria-describedby="dropdown-header-label" @click="volumTableShow" >
                Volume
              </b-dropdown-item-button>
            </b-dropdown>
          </b-col>
          <!--  /  dropdown       -->
        </b-row>
        <b-row class="table-head">
          <b-col cols="4" class="">
            <strong> Code </strong>
          </b-col>
          <b-col cols="4" class="">
            <strong> Type </strong>
          </b-col>
          <b-col cols="4" v-show="dimantionShow">
            <strong> Dimantions </strong>
          </b-col>
          <b-col cols="4" v-show="volumShow">
            <strong> Volume </strong>
          </b-col>
          <b-col cols="4" v-show="coutsShow">
            <strong> Couts </strong>
          </b-col>
        </b-row>
        <b-row class="table-item">
          <b-col cols="4">
            <p class="small-text">A1</p>
          </b-col>
          <b-col cols="4">
            <p class="small-text">Carton - Carry-on</p>
          </b-col>
          <b-col cols="4" v-show="dimantionShow">
            <p class="small-text">30 x 20 x 10 cm</p>
          </b-col>
          <b-col cols="4" v-show="volumShow">
            <p class="small-text">6000 cm<sup>3</sup></p>
          </b-col>
          <b-col cols="4" v-show="coutsShow">
            <p class="small-text">1.8 DH</p>
          </b-col>
        </b-row>
        <b-row class="table-item">
          <b-col cols="4">
            <p class="small-text">0B0</p>
          </b-col>
          <b-col cols="4">
            <p class="small-text">Bag - Carry-on</p>
          </b-col>
          <b-col cols="4" v-show="dimantionShow">
            <p class="small-text" >30 x 20 cm</p>
          </b-col>
          <b-col cols="4" v-show="volumShow">
            <p class="small-text">--</p>
          </b-col >
          <b-col cols="4" v-show="coutsShow">
            <p class="small-text">--</p>
          </b-col>
        </b-row>
        <b-row class="table-item">
          <b-col cols="4">
            <p class="small-text">1C0</p>
          </b-col>
          <b-col cols="4">
            <p class="small-text">Enveloppe plus - Nano</p>
          </b-col>
          <b-col cols="4" v-show="dimantionShow">
            <p class="small-text" >5 x 5 cm</p>
          </b-col>
          <b-col cols="4" v-show="volumShow">
            <p class="small-text">--</p>
          </b-col>
          <b-col cols="4" v-show="coutsShow">
            <p class="small-text">1.10 DH</p>
          </b-col>
        </b-row>
        <b-row class="table-item">
          <b-col cols="4">
            <p class="small-text">W0</p>
          </b-col>
          <b-col cols="4">
            <p class="small-text">Wrap - Oversize</p>
          </b-col>
          <b-col cols="4" v-show="dimantionShow">
            <p class="small-text" >-</p>
          </b-col>
          <b-col cols="4" v-show="volumShow">
            <p class="small-text">--</p>
          </b-col>
          <b-col cols="4" v-show="coutsShow">
            <p class="small-text">3.00 DH</p>
          </b-col>
        </b-row>
      </div>
<!-- ************************ end packing mobile version ****************************************************-->
      <b-row class="top-space">
        <b-col cols="1"> </b-col>
        <b-col cols="10" class="block-black mb-5">
          <p class="title">
            <b-icon-question-circle-fill class="mr-2" />
            <strong> Le saviez-vous </strong>
          </p>
          <h3>
            <strong class="white-text "> Contre-remboursement </strong>
          </h3>
          <p class="m-0">
            Althiqa vous donne la possebilite d'effectué l'encaissement à la
            livraison de la marchandise par epsece, TPE ou un cheque si vous
            choisiez.
          </p>
        </b-col>
      </b-row>
<!--      <b-row class="mt-5">-->
<!--        <b-col>-->
<!--          <h2>-->
<!--            <strong> Plan de livraison </strong>-->
<!--          </h2>-->
<!--          <p class="text-dark">-->
<!--            ALTHIQA a mis a votre dispositions toute ces outils et services de-->
<!--            sorte que vous pouvez trouver la combinaison qui fonctionne le mieux-->
<!--            pour vous et vos objectifs.-->
<!--          </p>-->
<!--        </b-col>-->
<!--      </b-row>-->
<!--      <div class="block-shadow mb-3">-->
<!--        <b-row>-->
<!--          <b-col>-->
<!--            <h4>-->
<!--              <strong> Comparatif de plans de livraison recommande </strong>-->
<!--            </h4>-->
<!--          </b-col>-->
<!--        </b-row>-->
<!--        <b-row class="table-head">-->
<!--          <b-col cols="4"> </b-col>-->
<!--          <b-col cols="4">-->
<!--            <strong> Standard </strong>-->
<!--          </b-col>-->
<!--          <b-col cols="4">-->
<!--            <strong> Fullfiment </strong>-->
<!--          </b-col>-->
<!--        </b-row>-->
<!--        <b-row class="table-item-no-style">-->
<!--          <b-col cols="4"> </b-col>-->
<!--          <b-col cols="4">-->
<!--            <strong>-->
<!--              <span class="big-title"> 13.00 </span>-->
<!--              Dirhams / Livraison-->
<!--            </strong>-->
<!--            <p class="small-text">+ Frais additionnel</p>-->
<!--          </b-col>-->
<!--          <b-col cols="4">-->
<!--            <strong>-->
<!--              <span class="big-title"> 13.00 </span>-->
<!--              Dirhams / Livraison-->
<!--            </strong>-->
<!--            <p class="small-text">+ Frais additionnel</p>-->
<!--            <strong class="small-text">-->
<!--              ** Tarification favorable pour +50 Livraisons quotidien-->
<!--            </strong>-->
<!--          </b-col>-->
<!--        </b-row>-->
<!--        <b-row class="table-title">-->
<!--          <b-col>-->
<!--            <h4 class="no-margin">-->
<!--              <strong> Aperçu </strong>-->
<!--            </h4>-->
<!--          </b-col>-->
<!--        </b-row>-->
<!--        <b-row class="table-item-no-style">-->
<!--          <b-col cols="4">-->
<!--            <p>Ce plan pourrait vous convenir si...</p>-->
<!--          </b-col>-->
<!--          <b-col cols="4">-->
<!--            <ul>-->
<!--              <li>Vous vendez moins de 5 unités par jour</li>-->
<!--              <li>-->
<!--                Vous changer toujours la liste de produits que vous vendez-->
<!--              </li>-->
<!--              <li>-->
<!--                Vous ne prévoyez pas de faire de la publicité ou d'utiliser les-->
<!--                outils des analyses avancés-->
<!--              </li>-->
<!--            </ul>-->
<!--          </b-col>-->
<!--          <b-col cols="4">-->
<!--            <ul>-->
<!--              <li>Vous vendez plus de 5 unités par jour</li>-->
<!--              <li>Vous souhaitez delege la gestion de vos des stocks</li>-->
<!--              <li>-->
<!--                Vous etes a la besion de la soustraitance de la relation client-->
<!--              </li>-->
<!--              <li>Vous prévoyez de faire de la publicité</li>-->
<!--              <li class="no-margin">-->
<!--                Vous souhaitez utiliser les outils d'analyse avancés et les APIs-->
<!--                et les rapports interactif-->
<!--              </li>-->
<!--            </ul>-->
<!--          </b-col>-->
<!--        </b-row>-->
<!--        <b-row class="table-title">-->
<!--          <b-col>-->
<!--            <h4 class="no-margin">-->
<!--              <strong> Caractéristiques du plan de livraison </strong>-->
<!--            </h4>-->
<!--          </b-col>-->
<!--        </b-row>-->
<!--      </div>-->
    </b-container>

    <div class="simulator-block top-space " id="simulateur-de-frais">
      <b-container>
        <b-row>
          <b-col cols="9" class="no-padding text-center mx-auto">
            <span class="slogon-text white-text"> Simulateur de frais </span>
            <h2 class="mt-3">
              <strong class="white-text  ">
                Estimer votre tarif de livraison
              </strong>
            </h2>
            <p>
              Le coût de livraison sur Althiqa.com varie en fonction des
              caractéristiques de votre produit, de la méthode d'exécution, des
              services optionnels et d'autres facteurs. Utilisez ce simulateur
              pour générer une estimation approximative de vos frais de
              livraison.
            </p>
          </b-col>
        </b-row>
        <b-row class="mt-5">
          <b-col  lg="7" sm="12" class="light-black-block">
            <h4 class="mb-4">
              <strong class="white-text">
                Informations sur la livraison
              </strong>
            </h4>
            <b-row>
              <b-col lg="6" sm="12" class="right-separator">
                <div class="text-field">
                  <label for="weight" class="small-text">
                    Poids en kg
                  </label>
                  <b-tooltip target="weight" title="weight infos" variant="light" custom-class="white-tooltip"></b-tooltip>
                  <button class="small-text ml-1 btn-transparent" id="weight">
                      <b-icon-info-circle-fill/>
                  </button>
                  <input type="number" name="weight" ref="weight" placeholder="0.00">
                </div>
                <br>

                <label for="length" class="small-text dimentions-label-color"
                  >Dimentions en cm
                  <strong>(Longueur x Largeur x Hauteur)</strong></label
                >
                <b-row>
                  <b-col cols="4">
                    <div class="text-field">
                      <input type="number" name="length" ref="length" placeholder="0.00">
                    </div>
                  </b-col>
                  <b-col cols="4">
                    <div class="text-field">
                      <input type="number" name="width" ref="width" placeholder="0.00">
                    </div>
                  </b-col>
                  <b-col cols="4">
                    <div class="text-field">
                      <input type="number" name="height" ref="height" placeholder="0.00">
                    </div>
                  </b-col>
                </b-row>
                <br>
                <label for="category" class="small-text dimentions-label-color">Catégorie d'envoi</label>
                <v-select
                  @input="setCategory"
                  :options="[
                    {label: 'Livraison avec ramassage', code: '1'},
                    {label: 'Fulfilment', code: '2'},
                    {label: 'Messagerie', code: '3'},
                  ]"
                  class="calculator-v-select"
                ></v-select>
              </b-col>
              <b-col class="mt-2-mobile" sm="12" lg="6">
                <label for="height" class="small-text dimentions-label-color">Ville de départ</label>
                <v-select
                  @input="setDepartureCity"
                  value="Casablanca"
                  :options="['Casablanca']"
                  class="calculator-v-select"
                ></v-select>
                <br />
                <label for="category" class="small-text dimentions-label-color">Ville d'arrivée</label>
                <v-select
                  @input="setArrivalCity"
                  :options="[
                    {label : 'Agadir', value: '35'},
                    {label : 'Casablanca', value: '25'},
                    {label : 'Fes', value: '35'},
                    {label : 'Marrakech', value: '35'},
                    {label : 'Mekness', value: '35'},
                    {label : 'Rabat', value: '35'},
                    {label : 'Salé', value: '35'},
                    {label : 'Tanger', value: '35'},
                    {label : 'Temara', value: '35'},
                  ]"
                  class="calculator-v-select"></v-select>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <button class="forword-link forword-button mt-3" @click="calcEstimate()">
                  <strong>
                    Calculer l'estimation
                    <b-icon-arrow-right-short class="link-icon" />
                  </strong>
                </button>
              </b-col>
            </b-row>
          </b-col>
          <b-col class="pl-4">
            <h4>
              <strong class="white-text">Coût de livraison</strong>
            </h4>
            <b-row>
              <b-col>
                <div class="estimate-total">
                  {{ estimate.toFixed(2) }}<span class="currency"> DHs</span>
                </div>
              </b-col>
            </b-row>
<!--            <b-row>-->
<!--              <b-col>-->
<!--                <b-button-->
<!--                  :class="visible ? null : 'collapsed'"-->
<!--                  :aria-expanded="visible ? 'true' : 'false'"-->
<!--                  aria-controls="collapse-4"-->
<!--                  @click="visible = !visible"-->
<!--                  class="estimate-details">-->
<!--                  Voir le detail +-->
<!--                </b-button>-->
<!--                <b-collapse id="collapse-4" v-model="visible" class="mt-2">-->
<!--                  <b-card>I should start open!</b-card>-->
<!--                </b-collapse>-->
<!--              </b-col>-->
<!--            </b-row>-->
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="other-fees">

    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/styles/variables";


.text-field {
        label {
            color: $text-primary-light-color;
        }

        input {
            display: block;
            padding: 10px 15px;
            border: 0;
            border-radius: 4px;
            background: black;
            color: white;
            width: 100%;
        }

        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
        -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        input[type=number] {
            -moz-appearance: textfield;
        }

        input:focus-visible {
            outline: none;
        }

        button svg {
            color: $text-primary-light-color;
        }
    }

    .tooltip-light {
        background: white;
        padding: 10px;
    }

    .white-tooltip {
        opacity: 1;
        font-weight: bold;
    }

.forword-button {
  background: transparent;
  border: 0;
  strong {
    color: white;
  }
}

.estimate-details {
  margin-top: 20px;
  background: transparent;
  border: 0;
  color: white;
  font-size: 1.4rem;
}

.estimate-details:hover {
  background-color: #393939;
}

.estimate-total {
  background-color: $primary;
  font-size: 96px;
  padding: 0px 15px;
  font-weight: bold;
  border: 1px solid $primary;
  border-radius: 15px;
  .currency {
    font-size: 42px;
  }
}

.other-fees {
  height: 500px;
}

.dimentions-label-color {
  color: #909090;
  strong {
    color: #909090;
  }
}

.fullwidth-bg {
  background-color: #ffc4401a;
  height: 480px;
  overflow-x: hidden;
}

.fullwidth-bg::after {
  content: "";
  position: absolute;
  background-image: url(~@/assets/imgs/bg-pricing.svg);
  width: 100%;
  height: 262px;
  top: 560px;
}

.fullwidth-bg-mobile {
  background-color: #ffc4401a;
}

.fullwidth-bg-mobile::after {
  content: "";
  position: absolute;
  background-image: url(~@/assets/imgs/bg-pricing.svg);
  width: 100%;
  height: 262px;
  top: 904px;
}

h4 {
  line-height: 2rem;
}

.side-picture img {
  margin-top: -60px;
  width: 100%;
}

.block-shadow-no-border {
  box-shadow: 0px 0px 30px #00000019;
  border-radius: 14px;
  height: 100%;
  padding: 35px 30px;
  background-color: white;
}

.slider-menu {
  top: -450px;
  position: relative;
  height: 1px;
}

.simulator-block {
  background-color: black;
  padding: 100px 0;
}

.package-dropdown-btn{
  background-color: $primary;
  border-radius: 20px;
  position: relative;
  right: 5px;
  top: 5px;
  }

</style>

<script>
import {
  BIconArrowDownShort,
  BIconQuestionCircleFill,
  BIconArrowRightShort,
} from "bootstrap-vue";
export default {
  methods: {
    setCategory(value) {
      this.category = value
    },
    setDepartureCity(value) {
      this.departureCity = value
    },
    setArrivalCity(value) {
      this.arrivalCity = value
    },
    calcEstimate() {
      let weight = this.$refs.weight.value
      let height = this.$refs.height.value
      let length = this.$refs.length.value
      let width = this.$refs.width.value
      let activeWeight = 0
      let dim = 0
      if ( height != '' && length != '' && width != '' && weight != '') {
        dim = (height * length * width)/5000
        activeWeight = dim>weight ? dim : weight
      }

      if ( activeWeight !== 0 && this.category != null && this.arrivalCity != null) {
        let additionalWeight = 0
        switch (this.category.code) {
          case '3' :
            if(activeWeight-15 > 0) {
              additionalWeight = activeWeight-15
              let additioalCost = Math.ceil(additionalWeight/10) * 15
              this.estimate = 25 + additioalCost
            } else {
              this.estimate = 25
            }
            break;
          case '2' :
            if(activeWeight-5 > 0) {
              additionalWeight = activeWeight-5
              let additioalCost = Math.ceil(additionalWeight) * 1.5
              this.estimate =  parseInt(this.arrivalCity.value) + additioalCost + 5
            } else {
              this.estimate = parseInt(this.arrivalCity.value) + 5
            }
            break;
          case '1' :
            if(activeWeight-5 > 0) {
              additionalWeight = activeWeight-5
              let additioalCost = Math.ceil(additionalWeight) * 1.5
              this.estimate =  parseInt(this.arrivalCity.value) + additioalCost
            } else {
              this.estimate = parseInt(this.arrivalCity.value)
            }
            break;
        }
      } else if (this.category != null && this.arrivalCity != null) {
        switch (this.category.code) {
          case '3' :
            this.estimate = 25
            break
          case '2' :
            this.estimate = parseInt(this.arrivalCity.value) + 5
            break
          case '1' :
            this.estimate = parseInt(this.arrivalCity.value)
            break
        }
      }
    },
    dimantionTableShow(){
      this.volumShow = false ;
      this.coutsShow = false ;
      this.dimantionShow = true ;
      this.dropdownPackageLabel = 'dimations'
    },
    volumTableShow(){
      this.volumShow = true ;
      this.coutsShow = false ;
      this.dimantionShow = false ;
      this.dropdownPackageLabel = 'volume'
    },
  coutsTableShow(){
    this.volumShow = false ;
    this.coutsShow = true ;
    this.dimantionShow = false ;
    this.dropdownPackageLabel = 'couts'
  }
  },

  data () {
    return {
      visible: false,
      departureCity: 0,
      arrivalCity: null,
      estimate: 0,
      category: null,
      coutsShow : true,
      volumShow:false,
      dimantionShow:false,
      dropdownPackageLabel : 'couts',
    }
  },
  name: "Tarification",
  components: {
    BIconArrowDownShort,
    BIconQuestionCircleFill,
    BIconArrowRightShort,
  },
};
</script>