<template>
  <div class="p-403" id="top">
      <b-container class="text-center">
          <b-row class="mt-4 margin-mobile">
                <b-col lg="12">
                    <span class="slogon-text">403</span>
                </b-col>
          </b-row>
          <b-row class="margin-mobile mt-2">
              <b-col lg="12">
                    <h1 class="big-title">
                        Nous sommes vraiment désolés, cette information est toujours privée
                    </h1>
              </b-col>
              <b-col lg="12" class="grey">
                  Essayez d'accéder à votre console<br> sur seller.althiqa.ma
              </b-col>
          </b-row>
      </b-container>
  </div>
</template>

<script>
export default {
    name: "403",
    methods: {
        scrollToTop() {
            window.scrollTo(0,0);
        },
    },
    mounted () {
        this.scrollToTop()
    }
}
</script>

<style lang="scss" scoped>
</style>
